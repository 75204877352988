var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t(_vm.title) } },
    [
      _c("span", { attrs: { slot: "extra" }, slot: "extra" }, [
        _vm._v(_vm._s(_vm.joinInvoiceForm.status || "-"))
      ]),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-form-model",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.joinInvoiceForm,
                    rules: _vm.formRules,
                    "label-col": { xs: { span: 24 }, lg: { span: 8 } },
                    "wrapper-col": { xs: { span: 24 }, lg: { span: 12 } },
                    layout: "vertical"
                  },
                  on: { submit: _vm.findInvoices }
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { prop: "branch", label: _vm.$t("lbl_branch") } },
                    [
                      _c("CSelectBranch", {
                        attrs: { "is-disabled": _vm.disableForm },
                        model: {
                          value: _vm.joinInvoiceForm.branch,
                          callback: function($$v) {
                            _vm.$set(_vm.joinInvoiceForm, "branch", $$v)
                          },
                          expression: "joinInvoiceForm.branch"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        prop: "joinInvoiceNo",
                        label: _vm.$t("lbl_join_invoice_no")
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { "read-only": "" },
                        model: {
                          value: _vm.joinInvoiceForm.joinInvoiceNo,
                          callback: function($$v) {
                            _vm.$set(_vm.joinInvoiceForm, "joinInvoiceNo", $$v)
                          },
                          expression: "joinInvoiceForm.joinInvoiceNo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        prop: "customerCode",
                        label: _vm.$t("lbl_customer_code")
                      }
                    },
                    [
                      _c("CSelectCustomerCode", {
                        attrs: { disabled: _vm.disableForm },
                        on: { "on-select": _vm.onSelectCustomerCode },
                        model: {
                          value: _vm.joinInvoiceForm.customerCode,
                          callback: function($$v) {
                            _vm.$set(_vm.joinInvoiceForm, "customerCode", $$v)
                          },
                          expression: "joinInvoiceForm.customerCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        prop: "customerName",
                        label: _vm.$t("lbl_customer_name")
                      }
                    },
                    [
                      _c("CSelectCustomer", {
                        attrs: { disabled: _vm.disableForm },
                        on: {
                          "on-select": function(ref) {
                            var meta = ref.meta

                            return _vm.onchangeCustomerName(meta)
                          }
                        },
                        model: {
                          value: _vm.joinInvoiceForm.customerName,
                          callback: function($$v) {
                            _vm.$set(_vm.joinInvoiceForm, "customerName", $$v)
                          },
                          expression: "joinInvoiceForm.customerName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        prop: "createdDate",
                        label: _vm.$t("lbl_created_date")
                      }
                    },
                    [
                      _c("a-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          disabled: "",
                          format: _vm.DEFAULT_DATE_FORMAT
                        },
                        model: {
                          value: _vm.joinInvoiceForm.createdDate,
                          callback: function($$v) {
                            _vm.$set(_vm.joinInvoiceForm, "createdDate", $$v)
                          },
                          expression: "joinInvoiceForm.createdDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        prop: "exchangeDate",
                        label: _vm.$t("lbl_exchange_date")
                      }
                    },
                    [
                      _c("a-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          format: _vm.DEFAULT_DATE_FORMAT,
                          disabled: _vm.disableForm
                        },
                        model: {
                          value: _vm.joinInvoiceForm.exchangeDate,
                          callback: function($$v) {
                            _vm.$set(_vm.joinInvoiceForm, "exchangeDate", $$v)
                          },
                          expression: "joinInvoiceForm.exchangeDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { prop: "dueDate", label: _vm.$t("lbl_due_date") }
                    },
                    [
                      _c("a-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          format: _vm.DEFAULT_DATE_FORMAT,
                          disabled: _vm.disableForm
                        },
                        model: {
                          value: _vm.joinInvoiceForm.dueDate,
                          callback: function($$v) {
                            _vm.$set(_vm.joinInvoiceForm, "dueDate", $$v)
                          },
                          expression: "joinInvoiceForm.dueDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { prop: "currency", label: _vm.$t("lbl_currency") }
                    },
                    [
                      _c("CSelectCurrency", {
                        attrs: {
                          "prop-default": _vm.isModeCreate,
                          disabled: _vm.disableForm
                        },
                        model: {
                          value: _vm.joinInvoiceForm.currency,
                          callback: function($$v) {
                            _vm.$set(_vm.joinInvoiceForm, "currency", $$v)
                          },
                          expression: "joinInvoiceForm.currency"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "wrapper-col": {
                          span: 24
                        }
                      }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            disabled: _vm.disableForm,
                            icon: "search",
                            type: "primary",
                            loading: _vm.loadingFind
                          },
                          on: { click: _vm.findInvoices }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lbl_find_invoices")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [0, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("TableCustom", {
                attrs: {
                  "data-source": _vm.invoicesData,
                  columns: _vm.tableColumns,
                  scroll: { x: "calc(700px + 50%)", y: 400 },
                  paginationcustom: true,
                  "default-pagination": false,
                  loading: _vm.loadingFind,
                  "get-checkbox-props": _vm.tableCheckboxProps,
                  "on-select-change": _vm.onSelectChange,
                  "selected-row-keys": _vm.selectedRowKeys
                },
                scopedSlots: _vm._u([
                  {
                    key: "custom-footer",
                    fn: function() {
                      return [
                        _c(
                          "a-row",
                          {
                            staticClass: "flex-column align-items-end",
                            attrs: { type: "flex" }
                          },
                          [
                            _c(
                              "a-col",
                              { attrs: { xs: { span: 24 }, lg: { span: 8 } } },
                              [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lbl_grand_total")) +
                                      " : " +
                                      _vm._s(
                                        _vm._f("currency")(_vm.grandTotal)
                                      ) +
                                      " "
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.totalData,
                  "page-size-set": _vm.limit,
                  "id-pagination": "pagination1"
                },
                on: {
                  "response-pagesize-change": _vm.responsePageSizeChange,
                  "response-currentpage-change": _vm.responseCurrentPageChange
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12, align: "end" } },
            [
              _c(
                "a-tag",
                {
                  staticStyle: { "font-size": "13px" },
                  attrs: { color: "grey" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("lbl_total_found")) +
                      " : " +
                      _vm._s(_vm.totalData) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { staticClass: "mt-3", attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        disabled: !_vm.disableForm || _vm.isCancelled,
                        type: "danger",
                        loading: _vm.loadingCancel
                      },
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        disabled: _vm.disableForm,
                        type: "primary",
                        loading: _vm.loadingSubmit,
                        icon: "save"
                      },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }