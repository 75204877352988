






































































































































































































import { ResponsePagination } from "@/models/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataResponseListJoinInvoiceAndPrepayment, RequestPayloadJoinInvoice } from "@interface/invoice.interface";
import { invoiceServices } from "@service/invoice.service";
import moment, { Moment } from "moment";
import { Component, Ref, Vue } from "vue-property-decorator";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
import { ContactData } from "@/models/interface-v2/contact.interface";
import { Mode } from "@/models/enums/global.enum";
import { JOIN_INVOICE_STATUS } from "@enum/join-invoice.enum";
import { Decimal } from "decimal.js-light";
import MNotificationVue from "@/mixins/MNotification.vue";

@Component({
  components: {
    CSelectCustomer: () => import("@/components/shared/select-customer/CSelectCustomer.vue"),
    CSelectCurrency: () => import("@/components/shared/select-currency/CSelectCurrency.vue"),
    CSelectCustomerCode: () => import("@/components/shared/select-customer-code/CSelectCustomerCode.vue"),
  },
  mixins: [
    MNotificationVue,
  ]
})
export default class JoinInvoicesFrom extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  @Ref("form") form;
  joinInvoiceForm = {
    branch: "",
    joinInvoiceNo: "",
    customerName: "",
    customerCode: "",
    customerId: "",
    createdDate: moment(),
    exchangeDate: null as Moment | null | string,
    dueDate: null as Moment | null | string,
    currency: "",
    status: "",
  };
  selectedRowKeys = [] as number[]
  loadingFind = false
  loadingSubmit = false
  loadingCancel = false
  id = ""
  page = 1
  limit = DEFAULT_PAGE_SIZE
  totalData = 0
  formRules = {
    branch: [
      {
        required: true,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    customerName: [
      {
        required: true,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    customerCode: [
      {
        required: true,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    exchangeDate: [
      {
        required: true,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    dueDate: [
      {
        required: true,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    currency: [
      {
        required: true,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
  };
  title = "lbl_create_join_invoices";
  tableColumns = [
    {
      title: this.$t("lbl_ar_invoice_number").toString(),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_invoice_prepayment_number").toString(),
      dataIndex: "invoicePrepaymentNumber",
      key: "invoicePrepaymentNumber",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_invoice_date").toString(),
      dataIndex: "formatInvoiceDate",
      key: "formatInvoiceDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_due_date").toString(),
      dataIndex: "formatDueDate",
      key: "formatDueDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_invoice_total").toString(),
      dataIndex: "invoiceTotal",
      key: "invoiceTotal",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
  ];
  invoicesData: DataResponseListJoinInvoiceAndPrepayment[] = [];
  grandTotal = 0;
  disableForm = false;

  created(): void {
    if (this.$route.params.id) {
      this.title = "lbl_view_join_invoices";
      this.disableForm = true;
      this.id = this.$route.params.id;
      this.getDetailInvoice();
    } else {
      this.title = "lbl_create_join_invoices";
      this.disableForm = false;
    }
  }

  get isModeCreate(): boolean {
    return this.$route.meta.mode === Mode.CREATE;
  }

  get isSubmitted(): boolean {
    return this.joinInvoiceForm.status === JOIN_INVOICE_STATUS.SUBMITTED;
  }

  get isCancelled(): boolean {
    return this.joinInvoiceForm.status === JOIN_INVOICE_STATUS.CANCELLED;
  }

  tableCheckboxProps() {
    return {
      props: {
        disabled: this.isSubmitted,
      }
    };
  }

  onSelectCustomerCode({ meta }: { value: string, meta: ContactData }): void {
    this.joinInvoiceForm.customerId = meta.id;
    this.joinInvoiceForm.customerName = meta.fullName;
  }

  onchangeCustomerName({ meta }: { meta: ContactData }): void {
    this.joinInvoiceForm.customerName = meta.fullName;
    this.joinInvoiceForm.customerId = meta.id;
    this.joinInvoiceForm.customerCode = meta.customerNumber;
  }

  onSelectChange (value) {
    this.selectedRowKeys = value;
  }

  responsePageSizeChange(response: ResponsePagination): void {
    this.limit = response.size;
    this.page = 1;
    this.findInvoices();
  }

  responseCurrentPageChange(response: ResponsePagination): void {
    this.page = response.page;
    this.findInvoices();
  }

  assignSearch(key, value, and): string {
    if (key === "branch" && value) return and + `branchWarehouse.secureId~${value}`;
    else if (key === "customerId" && value) return and + `customer.secureId~${value}`;
    else if (key === "currency" && value) return and + `priceCurrency.secureId~${value}`;
    else if (key === "joinInvoiceNumber" && value) return and + `documentNumber~${value}`;
    else return "";
  }

  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach((key) => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  findInvoices(): void {
    this.form.validate((valid) => {
      if (valid) {
        const params:RequestQueryParamsModel = {
          page: this.page - 1,
          limit: this.limit,
        };
        params.search = this.dynamicSearch(this.joinInvoiceForm);
        this.loadingFind = true;
        invoiceServices
          .getListJoinInvoiceAndPrepayment(params)
          .then((response) => {
            this.totalData = response.totalElements;
            this.invoicesData = response.data.map((dataMap, index) => {
              return {
                ...dataMap,
                key: index,
                formatInvoiceDate: moment(dataMap.invoiceDate).format(DEFAULT_DATE_FORMAT),
                formatDueDate: moment(dataMap.dueDate).format(DEFAULT_DATE_FORMAT),
              };
            });
            this.grandTotal = response.data.reduce((a, b) => { return new Decimal(b.invoiceTotal).plus(a).toNumber(); }, 0);
          })
          .finally(() => this.loadingFind = false);
      }
    });
  }

  handleBack(): void {
      this.$router.push("/account-receivables/join-invoices");
  }

  handleCancel(): void {
    this.$confirm({
      title: this.$t("lbl_confirm_cancel"),
      onOk: () => {
        this.loadingCancel = true;
        invoiceServices
          .cancelJoinInvoice(this.id)
          .then(() => {
            this.showNotifSuccess("notif_cancel_success");
            this.disableForm = true;
            this.getDetailInvoice();
          })
          .finally(() => this.loadingCancel = false);
      },
      onCancel() {
        return;
      },
    });
  }

  handleSubmit(): void {
      this.form.validate((valid: boolean) => {
      if (valid) {
        if(this.selectedRowKeys.length > 0) {
          const payload:RequestPayloadJoinInvoice = {
            branchWarehouseId: this.joinInvoiceForm.branch,
            currencyId: this.joinInvoiceForm.currency,
            customerId: this.joinInvoiceForm.customerId,
            date: moment(this.joinInvoiceForm.createdDate).format(),
            dueDate: moment(this.joinInvoiceForm.dueDate).format(),
            exchangeDate: moment(this.joinInvoiceForm.exchangeDate).format(),
            invoiceJoinLines: this.invoicesData
              .filter((_dataFilter, index) => 
                this.selectedRowKeys.includes(index))
                  .map((dataMap) => {
                    return {
                      dueDate: dataMap.dueDate,
                      invoiceARId: dataMap.invoiceId,
                      invoiceDate: dataMap.invoiceDate,
                      invoicePrepaymentId: dataMap.invoicePrepaymentId,
                      totalInvoice: dataMap.invoiceTotal
                    };
                  })
          };
          this.loadingSubmit = true;
          invoiceServices
            .createJoinInvoice(payload)
            .then((response) => {
              this.showNotifSuccess("notif_submit_success", {document: response.documentNumber});
              this.$router.replace({ name: "accountreceivable.joininvoice.view", params: {id: response.id} });
            })
            .finally(() => this.loadingSubmit = false);
        } else {
          this.showNotifError("lbl_error_no_selected_data");
        }
      }
    });
  }

  getDetailInvoice (): void {
    invoiceServices
      .getDetailJoinInvoiceNumber(this.id)
      .then((response) => {
        this.joinInvoiceForm.branch = response.branchWarehouseId;
        this.joinInvoiceForm.joinInvoiceNo = response.documentNumber;
        this.joinInvoiceForm.customerName = response.customerName;
        this.joinInvoiceForm.customerId = response.customerId;
        this.joinInvoiceForm.customerCode = response.customerCode;
        this.joinInvoiceForm.createdDate = moment(response.createdDate);
        this.joinInvoiceForm.exchangeDate = moment(response.exchangeDate);
        this.joinInvoiceForm.dueDate = moment(response.dueDate);
        this.joinInvoiceForm.currency = response.currencyId;
        this.joinInvoiceForm.status = response.status;

        this.invoicesData = response.invoiceJoinLines.map((dataMap, i) => {
          return {
            key: i,
            invoiceNumber: dataMap.invoiceNumber,
            invoiceId: dataMap.invoiceARId as string || dataMap.invoicePrepaymentId as string,
            invoicePrepaymentId: dataMap.invoicePrepaymentId as string,
            invoicePrepaymentNumber: dataMap.invoicePrepaymentNumber,
            invoiceTotal: dataMap.totalInvoice,
            totalInvoice: dataMap.totalInvoice,
            invoiceDate: dataMap.invoiceDate,
            dueDate: dataMap.dueDate,
            formatInvoiceDate: moment(dataMap.invoiceDate).format(DEFAULT_DATE_FORMAT),
            formatDueDate: moment(dataMap.dueDate).format(DEFAULT_DATE_FORMAT),
            invoiceARId: dataMap.invoiceARId as string
          };
        });
        this.totalData = this.invoicesData.length;
        this.grandTotal = this.invoicesData.reduce((a, b) => { return a + b.invoiceTotal; }, 0);
      });
  }

}
